(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace/retail-commission-counter/assets/javascripts/retail-commission-counter.js') >= 0) return;  svs.modules.push('/components/marketplace/retail-commission-counter/assets/javascripts/retail-commission-counter.js');
"use strict";

const SHARE_MAX_PRICE = 150;
const recountRetailBalance = (soldShares, sharePrice, commissionPercent) => {
  let maxPlayableAmount = soldShares * parseInt(sharePrice, 10) / (1 + parseInt(commissionPercent, 10) / 100);
  maxPlayableAmount = Math.max(maxPlayableAmount, soldShares * parseInt(sharePrice, 10) - soldShares * SHARE_MAX_PRICE);
  return Math.floor(Math.round(maxPlayableAmount * 100) / 100);
};
setGlobal('svs.components.marketplace.retailCommissionCounter.recountRetailBalance', recountRetailBalance);

 })(window);